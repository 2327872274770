@import 'bootstrap/scss/bootstrap';

.menu {
  padding-bottom: 100px;

  &__top {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: map-get($spacers, 2);
    font-size: $font-size-sm;
  }

  &__icon {
    margin-right: map-get($spacers, 3);
    margin-bottom: 0 !important;
    padding: 0 !important;
    color: $dark !important;
  }
}

@media only screen and (min-width: 576px){
  .menu {
    &__top {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      margin-bottom: map-get($spacers, 4);
    }

    &__icon {
      margin-right: map-get($spacers, 4);
      margin-bottom: 0 !important;
      padding: 0 !important;
      color: $dark !important;
    }
  }
}