.tap-anywhere-to-continue {
  &__content {
    background-color: rgba(42, 42, 42, 0.9);
    border-radius: 90px;
    font-size: 1.5rem;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.5);
  }

  color: white;
  z-index: 999999;
  opacity: 0;
  padding: 3em;

  position: absolute;
  bottom: 0;
  left: 0;

  width: 100%;

  &--visible {
    transition: opacity 0.5s ease-in-out;
    opacity: 1;
  }
}