@import 'bootstrap/scss/bootstrap';

.paymethods {
  &__item {
    display: flex !important;
    align-items: center;
    justify-content: space-between;
    padding: map-get($spacers, 3) !important;
  }

  &__label {
    display: flex;
    align-items: center;
  }

  &__icon {
    width: 50px;
    margin-right: .5rem
  }

  &__img {
    width: 100%;
  }
}

@media only screen and (min-width: 576px){
  .paymethods {
    &__item {
      padding: map-get($spacers, 4) !important;
    }
  }
}
