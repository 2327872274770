@import 'bootstrap/scss/bootstrap';

.loading {
  .modal-dialog {
    width: 200px;
    margin: auto;
  }

  &__body {
    text-align: center !important;
    padding: map-get($spacers, 2);
  }

  &__anim {
    position: relative;
  }

  &__icon {
    position: absolute;
    left: 45%;
    top: 42%;
  }

  &__text {
    font-weight: bold;
    font-size: $h5-font-size;
    margin-bottom: 0;
  }
}

@media only screen and (min-width: 576px){
  .loading {
    .modal-dialog {
      width: 360px;
    }

    &__body {
      padding: map-get($spacers, 4);
    }

    &__icon {
      left: 47%;
      top: 42%;
    }

    &__text {
      font-size: $h4-font-size;
      margin-bottom: 0;
    }
  }
}