
.number-pad {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  
  gap: 0.75em;

  div {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}