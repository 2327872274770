@import 'bootstrap/scss/bootstrap';

.btn-num-pad {
  display: flex;
  justify-content: center;
  align-items: center;
  
  width: 2.5em;
  height: 2.5em;
  color: black;

  border-radius: 100%;
  border: 1px solid black;

  background-color: white;
  transition: background-color 0.1s;

  &:active {
    background-color: lightgray;
    transition: background-color 0.1s;
  }

  &--danger {
    border: 2px solid $danger;
    background-color: $danger;
    color: white;

    transition: background-color 0.1s, border-color 0.1s;

    &:active {
      background-color: #c22f3d;
      border-color: #c22f3d;
      transition: background-color 0.1s, border-color 0.1s;
    }
  }

  &--success {
    border: 2px solid $success;
    background-color: $success;
    color: white;

    transition: background-color 0.1s, border-color 0.1s;

    &:active {
      background-color: #23943d;
      border-color: #23943d;
      transition: background-color 0.1s, border-color 0.1s;
    }
  }

  &--gray {
    border: 2px solid lightgray;
    background-color: lightgray;

    transition: background-color 0.1s, border-color 0.1s;

    &:active {
      background-color: #c2c2c2;
      border-color: #c2c2c2;
      transition: background-color 0.1s, border-color 0.1s;
    }
  }
}