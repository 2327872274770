
.product-select-modal {
  display: flex;
  flex-direction: column;
  
  position: relative;
  
  &__title {
    display: flex;
    justify-content: space-between;
  }
  
  &__content {
    display: flex;
    flex-direction: column;
    
    overflow: auto;
    height: 100%;
  }
  
  &__special-instructions {
    margin-top: auto !important;
  }
  
  &__close-button-container {
    display: flex;
    justify-content: center;
    align-items: center;
    
    position: absolute;
    left: 1.5rem;
    top: 1.5rem;
    
    z-index: 9999;
    
    background-color: white;
    opacity: 0.9;
    
    border: none;
    border-radius: 100px;
    box-shadow: 0 0 2px rgba(0,0,0,0.80);
    
    width: 2.5rem;
    height: 2.5rem;
  }
}

@media only screen and (min-width: 769px) {
  .product-select-modal {
    height: 65vh;
    
    &--auto-height {
      height: auto;
    }
  }
}

.product-select-modal-footer {
  display: block !important;
}

.add-to-cart-button {
  width: 100%;
}

.add-to-cart-button-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 1.25em;
}

.add-to-cart-button-amount {
  margin-left: 1em;
  font-weight: normal;
}
