@import 'bootstrap/scss/bootstrap';

.checkout {
	&__nav {
		display: flex;
		justify-content: flex-start;
		align-items: center;
		margin-bottom: map-get($spacers, 2);
	}

	&__back {
		margin-right: map-get($spacers, 3);
		margin-bottom: 0;
		padding: 0 !important;
		color: $dark !important;
	}

	&__item {
		margin-bottom: map-get($spacers, 2);
		border: 0 !important;
		box-shadow: $box-shadow-sm;
	}

	&__iteminner {
		padding: map-get($spacers, 2) map-get($spacers, 3) !important;
	}

	&__name {
		margin-right: map-get($spacers, 2);
		margin-bottom: 0;
	}

	&__mod {
		color: $text-muted;
		margin-left: map-get($spacers, 4);
		margin-bottom: map-get($spacers, 1);
	}

	&__price {
		margin-bottom: 0;
		margin-left: map-get($spacers, 2);
		text-align: right;
	}
	
	&__quantity {
		display: flex;
		align-items: center;
		margin-left: map-get($spacers, 2);
	}
}

@media only screen and (min-width: 576px) {
	.checkout {
		&__nav {
			margin-bottom: map-get($spacers, 2);
		}

		&__item {
			margin-bottom: map-get($spacers, 2);
		}

		&__iteminner {
			padding: map-get($spacers, 3) map-get($spacers, 4) !important;
		}

		&__price {
			margin-left: map-get($spacers, 3);
		}

		&__quantity {
			margin-left: map-get($spacers, 3);
		}
	}
}