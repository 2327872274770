@import 'bootstrap/scss/bootstrap';

.revlist {
  &__item {
    display: flex !important;
    align-items: center;
    justify-content: space-between;
    padding: map-get($spacers, 2) map-get($spacers, 3) !important;
  }

  &__name {
    font-size: $h5-font-size;
    margin-bottom: 0;
  }

  &__logo {
    height: 3rem;
    width: 3rem;
    object-fit: cover;
  }
}

@media only screen and (min-width: 576px) {
  .revlist {
    &__name {
      font-size: $h4-font-size;
    }

    &__logo {
      height: 4rem;
      width: 4rem;
    }
  }
}