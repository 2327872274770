@import 'bootstrap/scss/bootstrap';

.topnav {
  display: flex;
  justify-content: space-between;
  align-items: center;

  &__title {
    font-size: $h6-font-size;
    font-weight: bolder;
  }
}


@media only screen and (min-width: 576px){
  .topnav {
    &__title {
      font-size: $h2-font-size;
      font-weight: bolder;
    }
  }
}