.welcome {
  height: 100%;

  h1 {
    margin-bottom: 2.3rem;
    font-weight: bolder;
  }
}

.thankyouColor
{
	color: rgb(0, 123, 255);
	font-size: 22px !important;
	font-weight:bold !important;
}
h1.display-1{
	padding-top:110px;
}

@media only screen and (max-width: 600px){
.display-1{
	font-size:3.5rem!important;
	padding-top:0px!important;
}
.welcome img{
	max-width:100%; padding:0px 20px;
}

}