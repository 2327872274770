
.pin-pad {
  font-size: 1.5rem;
  
  &__display {
    display: flex;
    justify-content: center;
    align-items: center;
    
    font-size: 3.5rem;
    letter-spacing: 0.5rem;
    
    padding-left: 0.5rem;
    height: 1em;
    margin-bottom: 0.5em;
  }
  
  &__number-pad-container {
    display: flex;
    justify-content: center;
  }
  
  &__submit {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 1em;
  }
}