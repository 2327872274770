@import 'bootstrap/scss/bootstrap';

$img-size: 80px;

.it {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  padding: map-get($spacers, 2) map-get($spacers, 3);

  &__metadata {
    min-width: 0;
  }

  &__name {
    font-size: 1.125rem;
    font-weight: 500;
  }

  &__descr {
    font-size: $font-size-sm;
  }

  &__price {
    margin-top: map-get($spacers, 1);
    font-size: $font-size-sm;
  }

  &__imagecontainer {
    display: flex;
    flex: 0 0 $img-size;
    width: $img-size;
    height: $img-size;
    margin-left: 1rem;
  }

  &__img {
    object-fit: cover;
    width: $img-size;
    height: $img-size;
    border-radius: calc($img-size / 2);
  }
}

@media only screen and (min-width: 576px){
  .it {
    padding: map-get($spacers, 2) map-get($spacers, 3);

    &__name {
      font-size: $h4-font-size;
      font-weight: 500;
    }

    &__price {
      margin-top: map-get($spacers, 2);
    }

    &__imagecontainer {
      display: flex;
      flex: 0 0 100px;
      width: 100px;
      height: 100px;
      margin-left: 1rem;
    }

    &__img {
      object-fit: cover;
      width: 100px;
      height: 100px;
      border-radius: 50px;
    }
  }
}