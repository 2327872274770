@import 'bootstrap/scss/bootstrap';

.confirm {
  .modal-body {
    padding-bottom: 50px;
  }

  &__text {
    text-align: center;
    padding: map-get($spacers, 5);
    margin-bottom: 0;
    font-size: $h4-font-size;
    font-weight: bold;
  }
}

@media only screen and (min-width: 576px){
  .confirm {
    .modal-body {
      padding-bottom: 100px;
    }
  }
}