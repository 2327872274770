@import 'bootstrap/scss/bootstrap';

.bbg {
  &__group {
    border-radius: 0 0 $border-radius-lg $border-radius-lg !important;
    background: green !important;
  }

  .btn-group > .btn {
    border-radius: 0;
  }
}

@media only screen and (min-width: 576px){
  .bbg {
    .btn {
      line-height: 2.5 !important;
      font-size: $font-size-lg !important;
    }

    .btn-group > .btn {
      padding: 1rem !important;
    }
  }
}