@import '../theme';
@import 'bootstrap/scss/bootstrap';

.tip-container {
  background: $gray-100;
  border-radius: $border-radius;

  &__title {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin: map-get($spacers, 1);
  }

  &__title > h2 {
    font-weight: bolder;
    font-size: $h4-font-size;
  }
}

.cc-container {
  margin: map-get($spacers, 1);
}

.mobile-container {
  margin: 0 -7px;
}

.tip {
  text-align: center;
  background: $card-bg;
  border: 1px solid $card-border-color;
  border-radius: $card-border-radius;
  box-shadow: $box-shadow-sm;
  padding: map-get($spacers, 3) 0;
  margin: map-get($spacers, 1);
  cursor: pointer;
  transition: border 300ms, color 200ms;

  &:hover {
    border: 1px solid $primary;
  }

  &--selected {
    border-color: $primary;
    color: $primary;
  }

  &__title {
    font-size: 1.3rem;
    margin: 0 map-get($spacers, 1) map-get($spacers, 1);
  }

  &__percent {
    font-weight: bold;
  }

  &__value {
    text-align: center;
    font-size: .75rem;
  }

  &__confirm {
    margin: map-get($spacers, 1);
  }
}

.tip-custom {
  &__title {
    font-size: 1.1rem;
  }
}

.tip-slider {
  padding: 0 map-get($spacers, 3);
}

@media (min-width: map-get($grid-breakpoints, 'md')) {
  .tip-container {
    &__title > h2 {
      font-size: $h2-font-size;
    }
  }

  .tip {
    padding: map-get($spacers, 3) map-get($spacers, 2);
    margin: map-get($spacers, 1);

    &__percent {
      font-size: $h5-font-size;
    }

    &__value {
      font-size: .9rem;
    }
  }
}

@media (min-width: map-get($grid-breakpoints, 'lg')) {
  .tip-container {
    padding: map-get($spacers, 4);

    &__title {
      margin: map-get($spacers, 3);
    }
  }

  .tip {
    padding: map-get($spacers, 4);
    margin: map-get($spacers, 3);

    &__percent {
      font-size: $h3-font-size;
    }

    &__title {
      margin: 0 map-get($spacers, 3);
      font-size: $h4-font-size;
    }

    &__value {
      font-size: 1.1rem;
    }

    &__confirm {
      margin: map-get($spacers, 3);
    }
  }

  .tip-custom {
    &__title {
      font-size: $h5-font-size;
      margin-bottom: map-get($spacers, 3);
    }
  }

  .cc-container {
    margin: map-get($spacers, 3);
  }

  .mobile-container {
    margin: 0 5px;
  }
}
