
.product-selection-group {
  background-color: white;
  &__header {
    padding: 1em 1em;

    &-title {
      display: flex;
      align-items: center;
      width: 100%;
      padding-bottom: .25em;

      h5 {
        margin: 0;
      }

      // The accordion icon
      i {
        margin-left: auto;

        transition: transform 0.2s;

        // Animate the accordion icon rotation when expanded
        &.expanded {
          transform: rotate(-180deg);
          transition: transform 0.2s;
        }
      }
    }
    
    &-requirements {
      font-size: 0.85em;
      white-space: pre;
    }
  }
  
  &__product {
    display: flex;
    align-items: center;
    justify-content: space-between;
    
    padding: .50em 1em;
    
    // Add separators lines between each element
    &:not(:last-child) {
      border-bottom: 1px solid lightgray;
    }
    
    &-checkbox {
      display: flex;
      align-items: center;
    }
    
    &-price {
      margin-left: auto;
      padding: 0 0.5em;
      font-weight: 500;
    }
  }
  
  label {
    margin: 0;
    // Allow click events to pass through to the container
    pointer-events: none;
  }
}