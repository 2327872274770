@import 'bootstrap/scss/bootstrap';

.mtn {
  &__img {
    height: 36px;
  }
}

@media only screen and (min-width: 576px){
  .mtn {
    &__img {
      height: 50px;
    }
  }
}