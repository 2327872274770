
.player-swipe {
    display: flex;
    flex-direction: column;
    align-items: center;
    
    &__swipe-animation {
        height: 37vh;
        width: 75%;
        margin: 1em 0;
    }
}