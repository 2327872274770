.order_type_button{
    display:flex !important;
    flex-direction: column;
    border-radius: 4px;
    align-items: center;
    justify-items: flex-end;
    cursor: pointer;
    padding: 5px 10px 5px !important; 
    height: 100%;
    width: 48%;
}
.order_type_button_group{
    display:flex;
    flex-direction: row;
    align-content: center;
    width: 90%;
    padding: 0 10px;
    gap: 1em;
}

.order_type_header{
    font-size: 5vw;
    font-weight: bold;
    text-align: center;
    padding-bottom: 15px;
}

.order_type_selection_image{
    flex: 1;
    height: 50%;
    width: 90%;
    align-self: center;
    justify-self: flex-end;
}

.order_type_selection_text{
    flex:0 0 10%;
    align-self: center;
    justify-items: flex-end;
    font-family: Arial, sans-serif;
    font-size:5vw;
    height: 21%;
    width: 100%;
}

.order_type_page{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height:90vh;
    width: 100%;
}