@import 'bootstrap/scss/bootstrap';

.pgl {
  &__pg {
    margin-bottom: map-get($spacers, 2);
  }

  &__card {
    .card-body {
      padding: 0;
    }
  }
}

@media only screen and (min-width: 576px){
  .pgl {
    &__pg {
      margin-bottom: map-get($spacers, 5);
    }

    &__card {
      .card-body {
        padding: map-get($spacers, 3);
      }
    }
  }
}