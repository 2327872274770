@import 'bootstrap/scss/bootstrap';

.menufilter {
  &__namecontainer {
    cursor: pointer;
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
  }

  &__selectedname {
    font-weight: bolder;
  }

  &__list {
    margin-bottom: map-get($spacers, 2) !important;
  }

  &__option {
    &--selected {
      font-weight: bolder;
    }
  }
}


@media only screen and (min-width: 576px) {
  .menufilter {
    &__namecontainer {
      cursor: pointer;
      display: flex;
      align-items: center;
      margin-bottom: 1rem;
    }

    &__selectedname {
      font-size: $h2-font-size;
      font-weight: bold;
    }

    &__list {
      margin-bottom: map-get($spacers, 4) !important;
    }

    &__item {
      padding: map-get($spacers, 3);
    }

    &__option {
      font-size: $h4-font-size;
    }
  }
}