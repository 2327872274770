@import 'bootstrap/scss/bootstrap';

.pl {
  &__prod {
    margin-bottom: map-get($spacers, 2);

    .card-body {
      padding: 0;
    }
  }
}

@media only screen and (min-width: 576px){
  .pl {
    &__prod {
      margin-bottom: map-get($spacers, 4);

      .card-body {
        padding: map-get($spacers, 3);
      }
    }
  }
}