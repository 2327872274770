@import './theme';
@import 'bootstrap/scss/bootstrap';

@mixin fullscreen-modal {
  .modal-dialog {
    width: 100%;
    max-width: none;
    height: 100%;
    margin: 0;
  }

  .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }

  .modal-body {
    overflow-y: auto;
  }
}

.app {
  height: 100%;

  .padded {
    padding: 2rem;
  }

  &__contentcontainer {
    padding-top: 62px;
    height: 100%;
  }

  .action-bar-container {
    position: fixed;
    width: 100%;
    bottom: 0;
    right: 0;
  }
}

.pt-toast-container {
  margin-bottom: 5rem;
}

.pt-toast {
  border-radius: 5px;
  padding: 0;
}

.pt-toast-body {
  margin: 0;
}

.modal {
  
  &--full-screen {
    @include fullscreen-modal;

    // Makes bootstrap modals full screen on mobile
    &-md {
      @media only screen and (max-width: 768px) {
        @include fullscreen-modal;
      }
    }
  }
}

@media only screen and (min-width: 576px){
  .btn-dark:not(:disabled):not(.disabled):active:focus,
  .btn-dark:not(:disabled):not(.disabled).active:focus,
  .show>.btn-dark.dropdown-toggle:focus {
    box-shadow: none !important;
  }

  .app {
    &__contentcontainer {
      padding-top: 80px;
    }
  }
}
