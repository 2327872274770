
.loading-with-result-modal {
  &__result-text {
    padding: 1em;

    &--failure {
      color: red;
    }
    
    &--animate {
      animation: floatInFromTop 0.5s ease-out;
    }
  }
}

@keyframes floatInFromTop {
  0% {
    opacity: 0;
    transform: translateY(-20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}