@import 'styling/theme';
@import './theme';
@import '../node_modules/@fortawesome/fontawesome-free/css/all.css';
@import '../node_modules/bootstrap/scss/bootstrap.scss';

html, body {
  height: 100%;

  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
  background-attachment: fixed;
}

#root {
  height: 100%;
}

button:focus,
.btn:focus,
.btn-primary:focus,
.btn-primary:not(:disabled):not(.disabled):active:focus {
  outline: none;
  box-shadow: none;
}

.btn {
  padding: .5rem 1.5rem;
}

.btn-thick {
  line-height: 2.5;
  padding: .5rem 1.5rem;
}

.breadcrumb-item + .breadcrumb-item::before {
  content: "> ";
}