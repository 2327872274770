
.player-swipe-page {
  display: flex;
  flex-direction: column;
  justify-content: center;
  
  // Prevents double tap on mobile from zooming in, which happens accidentally when entering a pin number.
  // See https://developer.mozilla.org/en-US/docs/Web/CSS/touch-action for details.
  touch-action: manipulation;
}

