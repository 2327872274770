@import 'bootstrap/scss/bootstrap';

.paylist {
  border: none !important;
  box-shadow: $box-shadow-sm;

  .card-body {
    padding: map-get($spacers, 2) map-get($spacers, 4);
    text-transform: uppercase;
    font-weight: bold;
  }

  &__pay {
    margin-bottom: map-get($spacers, 2);
    padding: map-get($spacers, 2) 0;
    background-color: $light;
    border-radius: $border-radius-sm;
  }

  &__name {
    margin-left: map-get($spacers, 3);
  }

  &__tip {
    margin-top: map-get($spacers, 1);
    color: $text-muted;
  }

  &__subtotal {
    margin: map-get($spacers, 2) 0;
  }

  &__total {
    margin: map-get($spacers, 2) 0;
    font-weight: bold;

    &--bal {
      font-size: $h4-font-size;
      font-weight: bolder;
    }
  }
}

@media only screen and (min-width: 576px){
  .paylist {
    .card-body {
      padding: 1.8rem;
    }

    &__payments {
      padding-bottom: map-get($spacers, 3);
    }

    &__pay {
      margin-bottom: map-get($spacers, 3);
      padding: map-get($spacers, 3) 0;
    }

    &__name {
      margin-left: map-get($spacers, 3);
    }

    &__tip {
      margin-top: map-get($spacers, 2);
    }

    &__subtotal {
      margin: map-get($spacers, 3) 0;
    }

    &__total {
      margin: map-get($spacers, 3) 0;

      &--bal {
        font-size: $h4-font-size;
      }
    }
  }
}