@import 'bootstrap/scss/bootstrap';

.itemized {
  border: none !important;
  box-shadow: $box-shadow-sm;

  .card-body {
    padding: map-get($spacers, 3);
  }

  &__header {
    text-transform: uppercase;
  }

  &__quantity {
    width: 32px;
    height: 32px;
    cursor: default;
    border-radius: 50%;
    border: none;
    margin-right: map-get($spacers, 2);
  }

  &__name {
    display: flex;
    align-items: center;
  }

  &__price {
    text-align: right;

    &--offset {
      display: flex;
      align-items: center;
      justify-content: flex-end;
    }
  }
}

@media only screen and (min-width: 576px){
  .itemized {
    .card-body {
      padding: 1.8rem 2.8rem,
    }

    &__quantity {
      width: 50px;
      height: 50px;
      cursor: default;
      border-radius: 50%;
      border: none;
      margin-right: map-get($spacers, 4);
    }
  }
}